<template>
  <div id="logo-switcher">
  
    <div class="label">Dark logo</div>
    <label for="darkLogo">
      <figure>
        <img v-if="logo.dark" v-bind:src="darkLogo" alt="Dark Logo" class="img-fluid">
      </figure>
      <input type="file"
        name="darkLogo"
        id="darkLogo"
        v-on:change="onChange($event, 'dark')">
    </label>
    <div class="text-right" v-if="dark">
      <button class="btn btn-link btn-sm"
        v-on:click="onCancel('dark')">
        Cancel
      </button>
      <button class="btn btn-primary btn-sm"
        v-on:click="onUpdate('dark')">
        Update logo
      </button>
    </div>

    <div class="label">Light logo</div>
    <label for="lightLogo">
      <figure class="light">
        <img v-if="logo.light" v-bind:src="lightLogo" alt="Dark Logo" class="img-fluid">
      </figure>
      <input type="file"
        name="lightLogo"
        id="lightLogo"
        v-on:change="onChange($event, 'light')">
    </label>
    <div class="text-right" v-if="light">
      <button class="btn btn-link btn-sm"
        v-on:click="onCancel('light')">
        Cancel
      </button>
      <button class="btn btn-primary btn-sm"
        v-on:click="onUpdate('light')">
        Update logo
      </button>
    </div>

  </div>
</template>

<script>
  import { firestore, storage, } from "@/services/firebase.service";
  export default {
    data() {
      return {
        dark: null,
        light: null,
      };
    },
    computed: {
      logo() {
        return this.$store.getters["system/logo"];
      },
      darkLogo() {
        return this.dark ? URL.createObjectURL(this.dark) : this.logo.dark;
      },
      lightLogo() {
        return this.light ? URL.createObjectURL(this.light) : this.logo.light;
      },
    },
    methods: {
      setFile(prop, value) {
        this.$set(this.$data, prop, value);
      },
      onChange(event, prop) {
        const file = event.target.files[0];
        this.setFile(prop, file);
      },
      onCancel(prop) {
        this.setFile(prop, null);
      },
      async onUpdate(prop) {
        const file = this.$data[prop];
        if (file) {
          try {
            const snapshot = await storage.ref("/logo/" + prop).put(file);
            const URL = await snapshot.ref.getDownloadURL();
            await firestore.doc("system/THEME").update({ [`logo.${prop}`]: URL, });
            this.$store.commit("system/SET_LOGO", { [prop]: URL, });
            this.setFile(prop, null);
          } catch(e) {
            console.error(e);
            this.$swal(
              "Oops!",
              "An error has occurred",
              "error",
            );
          }
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  label {
    cursor: pointer;
  }
  input[type="file"] {
    display: none;
    position: absolute;
    left: -5000px;
  }
  figure {
    background-color: #fafafa;
    border: dashed 1px #dadada;
    border-radius: 6px;
    margin-bottom: 0;
    padding: 1rem;
    &.light {
      background-color: #d1d1d1;
    }
  }
</style>