<template>
  <div id="control-panel"
    v-bind:class="{ open: open, }">
    <a href="javascript:;"
      class="trigger"
      v-on:click="toggleOffCanvas">
      <SettingsIcon color="#ffffff" class="spin-anything"/>
    </a>
    <div class="offcanvas">
      <div class="d-flex justify-content-between">
        <h5 class="m-0">Control Panel</h5>
        <a href="javascript:;"
          v-on:click="toggleOffCanvas">
          <XIcon color="#6d6d6d" class="pt-1"/>
        </a>
      </div>
      <div class="content">
        <LogoSwitcher/>
        <ColorSwitcher/>
        <HeaderSwitcher/>
      </div>
    </div>
  </div>
</template>

<script>
  import { SettingsIcon, XIcon, } from "vue-feather-icons";
  import LogoSwitcher from "@/components/core/control-panel/LogoSwitcher";
  import ColorSwitcher from "@/components/core/control-panel/ColorSwitcher";
  import HeaderSwitcher from "@/components/core/control-panel/HeaderSwitcher";
  export default {
    components: {
      SettingsIcon,
      XIcon,
      LogoSwitcher,
      ColorSwitcher,
      HeaderSwitcher,
    },
    data() {
      return {
        open: false,
      };
    },
    methods: {
      toggleOffCanvas() {
        this.open = !this.open;
      },
    },
  };
</script>

<style lang="scss">
  #control-panel {
    background-color: rgba(0, 0, 0, .5);
    bottom: 0;
    position: fixed;
    right: -100vw;
    width: 100vw;
    top: 0;
    z-index: 999999;
    .trigger {
      align-items: center;
      background-color: var(--aff-primary);
      border-radius: 8px 0px 0px 8px;
      display: flex;
      height: 42px;
      justify-content: center;
      position: fixed;
      right: 0;
      top: 320px;
      width: 42px;
    }
    &.open {
      right: 0;
      .offcanvas {
        box-shadow: -2px 0px 5px 3px rgba(0, 0, 0, .075);
        right: 0;
      }
    }
    .offcanvas {
      background-color: #fbfbfb;
      bottom: 0;
      padding: 1rem;
      position: fixed;
      right: -320px;
      top: 0;
      transition: right .5s ease;
      width: 320px;
    }
    .label {
      color: #1b1b25;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: .5px;
      margin: .75rem 0 .25rem;
      text-transform: uppercase;
    }
    .description {
      margin: 0 0 6px;
      opacity: .75;
    }
  }
</style>